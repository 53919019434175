import React from 'react';
import PropTypes from 'prop-types';

import Helmet from '../project/Helmet';
import CategoryForm from './category/CategoryForm';
import locationHOC from '../../components/locationProvider/locationHOC';
import NavigateButton from '../general/NavigateButton';
import {createFetchCategoryAdd} from '../../backend/apiCalls';
import {navigateToParametrized} from '../../lib/url';
import {ROUTES, QUERY_PARAMS} from '../../constants/navigation';
import {Location} from '../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import withDataHOC from '../dataProvider/withDataHOC';
import { GLOBAL_DATA } from '../../constants/globalData';

/**
 * @fero
 */

class CategoryAddPage extends React.PureComponent {
    static propTypes = {
        location: Location.isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    };

    render() {
        const {location, [GLOBAL_DATA.RELOAD_DATA]: reload} = this.props;
        return <React.Fragment>
            <Helmet
                title={t`Pridanie produktovej kategórie`}
            />
            <div className="p-3 full-size-height overflow-y-auto">
                <div className="mb-3 d-flex justify-content-between">
                    <div  style={{width: '20rem'}}>
                        <NavigateButton
                            to={ROUTES.HOME}
                        >
                            <Trans>Produkty</Trans>
                        </NavigateButton>
                    </div>
                    <div style={{width: '20rem'}}/>
                </div>
                <h2 className="mb-3 text-center">
                    <Trans>Pridanie produktovej kategórie</Trans>
                </h2>
                <div>
                    <CategoryForm
                        submitLabel={<Trans>Pridať</Trans>}
                        fetchFunc={createFetchCategoryAdd()}
                        onFinishSuccessful={(categoryId) => {
                            reload([GLOBAL_DATA.CATEGORIES, GLOBAL_DATA.CATEGORY_TREE]);
                            navigateToParametrized(location, ROUTES.HOME, {[QUERY_PARAMS.ID_PRODUCT_GROUP]: categoryId});
                        }}
                        isEdit={false}
                    />
                </div>
            </div>
        </React.Fragment>
    }

}

export default locationHOC(
    withDataHOC([GLOBAL_DATA.RELOAD_DATA])(CategoryAddPage)
);